import { Avatar, Card, Typography } from 'antd';
import Meta from 'antd/es/card/Meta';
import { useAppSelector } from '../../../hooks';
import { selectGroupDetails } from '../dashboardSlice';

export const GroupDetails = () => {
  const groupDetails = useAppSelector(selectGroupDetails);
  return (
    <>
      <Card>
        <Meta
          avatar={
            <Avatar
              src={`${process.env.REACT_APP_API_URL}/avatars/${groupDetails?.avatar}`}
              size={64}
            />
          }
          title={groupDetails?.name}
          description={`${groupDetails?.description}`}
        />
        <Typography.Text
          italic={true}
        >{`Created at ${groupDetails?.createdAt} by ${groupDetails?.createdBy}`}</Typography.Text>
      </Card>
    </>
  );
};
