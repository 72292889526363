import {
  SerializedError,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import api from '../../../api';
import { RootState } from '../../../store';
import { mergeObjectsFields } from '../../../utils/mergeObjectsFields';

export type GroupItemSider = {
  _id: string;
  groupId: string;
  name: string;
  avatar: string;
  unreadMessageCount: number;
};

export type ConversationItemSider = {
  _id: string;
  email: string;
  avatar: string;
  unreadMessageCount: number;
};

export const fetchGroupsThunk = createAsyncThunk(
  'sider/group/fetch',
  async () => {
    const res = await api.conversation.fetchGroupConversations();

    return res;
  }
);

export const addGroupThunk = createAsyncThunk(
  'sider/group/add',
  async (formData: FormData) => {
    const data = await api.group.createGroup(formData);

    return {
      _id: data._id,
      groupId: data.groupId,
      name: data.name,
      avatar: data.avatar,
      unreadMessageCount: data.unreadMessageCount,
    };
  }
);

export const addConversationThunk = createAsyncThunk(
  'sider/conversation/add',
  async (userId: string) => {
    const conv = await api.conversation.addConversation(userId);
    return conv.data;
  }
);

export const fetchConversationsThunk = createAsyncThunk(
  'sider/conversation/fetch',
  async () => {
    const conv = await api.conversation.fetchConversations();
    return conv;
  }
);

type State = {
  groupList: Array<GroupItemSider>;
  conversationList: Array<ConversationItemSider>;
  error: SerializedError | null;
};

const initialState: State = {
  groupList: [],
  conversationList: [],
  error: null,
};

export const siderSlice = createSlice({
  name: 'siderState',
  initialState,
  reducers: {
    addConversation(state, action) {
      state.conversationList = [...state.conversationList, action.payload];
    },
    addGroupConversation(state, action) {
      state.groupList = [...state.groupList, action.payload];
    },
    updateGroupItem(
      state,
      action: {
        payload: Partial<Omit<GroupItemSider, '_id'>> & {
          groupId: string;
        };
        type: string;
      }
    ) {
      const newState = state.groupList.map((item) => {
        if (item.groupId === action.payload.groupId) {
          item = mergeObjectsFields(item, action.payload);
        }
        return item;
      });
      state.groupList = newState;
    },
    updateConversationUnreadMessageCount(state, action) {
      state.conversationList = state.conversationList.map((conversation) => {
        return {
          ...conversation,
          unreadMessageCount:
            conversation._id === action.payload.conversation
              ? action.payload.count
              : conversation.unreadMessageCount,
        };
      });
    },
    updateConversationItem(
      state,
      action: {
        payload: Partial<ConversationItemSider> & {
          _id: string;
        };
      }
    ) {
      state.conversationList = state.conversationList.map((item) => {
        if (item._id === action.payload._id) {
          item = mergeObjectsFields(item, action.payload);
        }
        return item;
      });
    },
    updateGroupUnreadMessageCount(state, action) {
      state.groupList = state.groupList.map((conversation) => {
        return {
          ...conversation,
          unreadMessageCount:
            conversation._id === action.payload.conversation
              ? action.payload.count
              : conversation.unreadMessageCount,
        };
      });
    },
    clearSliderState: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGroupsThunk.fulfilled, (state, { payload }) => {
      state.groupList = payload;
    });
    builder.addCase(fetchGroupsThunk.rejected, (state, action) => {
      throw Error();
    });
    builder.addCase(fetchConversationsThunk.fulfilled, (state, { payload }) => {
      state.conversationList = payload;
    });
    builder.addCase(fetchConversationsThunk.rejected, (state, action) => {
      throw Error();
    });
    builder.addCase(addGroupThunk.rejected, (state, action) => {
      throw Error();
    });
  },
});

export const {
  addConversation,
  clearSliderState,
  addGroupConversation,
  updateGroupItem,
  updateConversationUnreadMessageCount,
  updateGroupUnreadMessageCount,
  updateConversationItem,
} = siderSlice.actions;
export const selectGroupList = (state: RootState) => state.sider.groupList;
export const selectConversationList = (state: RootState) =>
  state.sider.conversationList;
