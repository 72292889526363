import { useEffect, useState } from 'react';
import { Modal, Avatar, Button, message, Badge } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { addConversationThunk } from '../sider/siderSlice';
import {
  selectConversationId,
  selectUserModal,
  setUserModalWithSocketThunk,
} from '../dashboardSlice';
import { UserStatus, UserStatusKeys } from '../header/Header';
import { useAuth } from '../../../context/AuthContext';

export type UserDataModal = {
  _id: string;
  email: string;
  avatar: string;
  updatedAt: string;
  createdAt: string;
  status: UserStatusKeys;
  conversationId: string | null;
};

const UserProfileModal = ({
  onChatBtnClick,
}: {
  onChatBtnClick: (conversationId: string) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const conversationId = useAppSelector(selectConversationId);
  const { socket } = useAuth();

  const userModal = useAppSelector<null | undefined | UserDataModal>(
    selectUserModal
  );

  useEffect(() => {
    const openModal = userModal ? true : false;
    setIsOpen(openModal);
  }, [userModal]);

  const handleChatBtnClick = async () => {
    try {
      if (!userModal) return;
      let convId = userModal?.conversationId;
      if (!convId) {
        const { payload } = await dispatch(addConversationThunk(userModal._id));
        message.success('Conversation created successfully');
        convId = payload._id;
      }
      onChatBtnClick(convId!);
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = async () => {
    await dispatch(setUserModalWithSocketThunk({ socket, userModal: null }));
  };

  return (
    <Modal
      title="User profile"
      open={isOpen}
      onOk={handleModalClose}
      onCancel={handleModalClose}
    >
      <Avatar
        src={`${process.env.REACT_APP_API_URL}/avatars/${userModal?.avatar}`}
        size={64}
      />
      <p>{userModal?.email}</p>

      {userModal?.status && userModal?.conversationId && (
        <Badge
          status={`${UserStatus[userModal.status].badge}`}
          text={`${UserStatus[userModal.status].name}`}
        />
      )}

      <p>Created at : {userModal?.createdAt}</p>
      {((userModal?.conversationId &&
        userModal.conversationId !== conversationId) ||
        !userModal?.conversationId) && (
          <Button onClick={handleChatBtnClick}>Chat</Button>
        )}
    </Modal>
  );
};

export default UserProfileModal;
