import {
  combineReducers,
  configureStore,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import {
  clearDashboardState,
  dashboardSlice,
} from './components/dashboard/dashboardSlice';
import {
  clearSliderState,
  siderSlice,
} from './components/dashboard/sider/siderSlice';

export const store = configureStore({
  reducer: combineReducers({
    sider: siderSlice.reducer,
    dashboard: dashboardSlice.reducer,
  }),
});

export const clearRootState = createAsyncThunk(
  'clearRootState',
  async (_, { dispatch }) => {
    dispatch(clearDashboardState());
    dispatch(clearSliderState());
  }
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
