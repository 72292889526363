import UploadAvatar from '../profile/UploadAvatar';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { updateGroupThunk, selectGroupDetails } from '../dashboardSlice';
import api from '../../../api';
import { Button, Input, Space, Typography, message } from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { useState } from 'react';
import TextArea from 'antd/es/input/TextArea';

export const GroupDetailsAdmin: React.FC = () => {
  const groupDetails = useAppSelector(selectGroupDetails);
  const [formState, setFormState] = useState({});
  const dispatch = useAppDispatch();

  const handleCustomRequest = async ({ file }: UploadRequestOption) => {
    try {
      const formData = new FormData();
      formData.append('avatar', file);

      const response = await api.group.uploadAvatar(
        groupDetails!._id,
        formData
      );
      if (response.status === 200) {
        message.success('File uploaded successfully');
      }
    } catch (error) {
      message.error('File upload failed');
    }
  };

  const handleEditGroupBtnClick = async (evt: any | PointerEvent) => {
    try {
      await dispatch(
        updateGroupThunk({ groupId: groupDetails!._id, data: formState })
      );
      message.success('Succesful edition!');
    } catch (err) {
      message.error('Edition failed');
    }
  };

  const handleInputChange = (event: {
    target: { value: string; id: string };
  }) => {
    const { value, id } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <Space direction="vertical" size="middle">
      <UploadAvatar
        avatarUrl={`${process.env.REACT_APP_API_URL}/avatars/${groupDetails?.avatar}`}
        onCustomRequest={handleCustomRequest}
      />
      Name
      <Input
        id={'name'}
        defaultValue={groupDetails?.name}
        onChange={handleInputChange}
      />
      Description
      <TextArea
        id={'description'}
        defaultValue={groupDetails?.description}
        rows={4}
        onChange={handleInputChange}
      />
      <Typography.Text
        italic={true}
      >{`Created at ${groupDetails?.createdAt} by ${groupDetails?.createdBy}`}</Typography.Text>
      <Button type="primary" onClick={handleEditGroupBtnClick}>
        Edit
      </Button>
    </Space>
  );
};
