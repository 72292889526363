import * as user from './user';
import * as conversation from './conversation';
import * as group from './group';

const api = {
  user,
  conversation,
  group,
};

export default api;
