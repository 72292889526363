import {
  Avatar,
  Button,
  Select,
  SelectProps,
  Space,
  Table,
  TableProps,
} from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  GroupParticipantDataType,
  fetchGroupParticipantsThunk,
  selectGroupDetails,
  selectGroupParticipants,
  updateGroupParticipantsThunk,
} from '../dashboardSlice';
import { UpdateGroupParticipantMethod } from '../../../api/group';
import api from '../../../api';
import { useAuth } from '../../../context/AuthContext';
import { ChatProps } from '../chat/Chat';

let timeout: ReturnType<typeof setTimeout> | null;

type Props = ChatProps;

export const GroupParticipants = ({ onUserAvatarClick }: Props) => {
  const groupDetails = useAppSelector(selectGroupDetails);
  const groupParticipants = useAppSelector(selectGroupParticipants);
  const [options, setOptions] = useState<SelectProps['options']>([]);
  const [selectedValue, setSelectedValue] = useState<undefined | string | null>(
    undefined
  );
  const { userProfile } = useAuth();
  const dispatch = useAppDispatch();

  const columns: TableProps<GroupParticipantDataType>['columns'] = [
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      render: (avatar, record) => (
        <Avatar
          src={`${process.env.REACT_APP_API_URL}/avatars/${avatar}`}
          style={{ cursor: 'pointer', backgroundColor: 'orange' }}
          onClick={() => onUserAvatarClick(record.email)}
        />
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => text,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => text,
    },
    {
      title: 'Admin',
      dataIndex: 'isAdmin',
      key: 'isAdmin',
      render: (isAdmin) => (isAdmin ? 'yes' : 'no'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space>
          {(groupDetails?.userIsAdmin ||
            userProfile?.email === record.email) && (
            <Button
              disabled={record.isLastAdmin}
              onClick={() => removeParticipant(record)}
            >
              {userProfile?.email === record.email ? 'Leave ' : 'Remove from '}
              group
            </Button>
          )}
          {groupDetails?.userIsAdmin && (
            <Button
              disabled={record.isLastAdmin}
              onClick={() => editParticipant(record)}
            >
              {record.isAdmin ? 'Remove' : 'Add'} admin
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const handleSearch = async (value: string) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    if (value) {
      timeout = setTimeout(async () => {
        const { data } = await api.user.searchByEmailFilteredByGroup(
          groupDetails!._id,
          value
        );

        setOptions([data.user]);
      }, 500);
    } else {
      setOptions([]);
    }
  };

  const handleAdd = () => {
    dispatch(
      updateGroupParticipantsThunk({
        groupId: groupDetails!._id,
        method: UpdateGroupParticipantMethod.add,
        data: { email: selectedValue! },
      })
    );
    setOptions([]);
    setSelectedValue(null);
  };

  const removeParticipant = (record: any) => {
    dispatch(
      updateGroupParticipantsThunk({
        groupId: groupDetails!._id,
        method: UpdateGroupParticipantMethod.remove,
        data: { email: record.email },
      })
    );
  };

  const editParticipant = (record: any) => {
    dispatch(
      updateGroupParticipantsThunk({
        groupId: groupDetails!._id,
        method: UpdateGroupParticipantMethod.edit,
        data: { email: record.email },
      })
    );
  };

  useEffect(() => {
    if (groupDetails?._id) {
      dispatch(fetchGroupParticipantsThunk(groupDetails._id));
    }
  }, [groupDetails?._id]);

  return (
    <>
      {groupDetails?.userIsAdmin && (
        <>
          <Select
            allowClear
            showSearch
            placeholder="Please select"
            style={{ width: '70%' }}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            filterOption={false}
            onSearch={handleSearch}
            onChange={setSelectedValue}
            notFoundContent={null}
            options={(options || []).map((d) => ({
              value: d?.email,
              label: d?.email,
            }))}
          />
          <Button
            onClick={handleAdd}
            type="primary"
            style={{ marginBottom: 16 }}
            disabled={!selectedValue}
          >
            Add a participant
          </Button>{' '}
        </>
      )}
      <Table columns={columns} dataSource={groupParticipants} />
    </>
  );
};
