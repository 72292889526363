import React, { useState, useEffect } from 'react';
import { UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Avatar, Badge, Layout, Menu } from 'antd';
import { useAuth } from '../../../context/AuthContext';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  ConversationItemSider,
  GroupItemSider,
  fetchConversationsThunk,
  fetchGroupsThunk,
  selectConversationList,
  selectGroupList,
} from './siderSlice';
import { selectConversationId } from '../dashboardSlice';

const { Sider: SiderAntd } = Layout;

type MenuItem = Required<MenuProps>['items'][number] & {
  onClick?: React.MouseEventHandler<HTMLElement>;
};

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  onClick?: React.MouseEventHandler<HTMLElement>
): MenuItem {
  return {
    label,
    key,
    icon,
    children,
    onClick,
  } as MenuItem;
}

type SiderProps = {
  onClickConversation: (conversationId: string) => void;
  onClickGroupConversation: (conversationId: string, groupId: string) => void;
};

const Sider = ({
  onClickConversation,
  onClickGroupConversation,
}: SiderProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const conversationList = useAppSelector(selectConversationList);
  const groupList = useAppSelector(selectGroupList);
  const [selectedMenuKeys, setSelectedMenuKeys] = useState<string[]>([]);
  const { userProfile } = useAuth();
  const conversationId = useAppSelector(selectConversationId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchGroupsThunk());
    dispatch(fetchConversationsThunk());
  }, [dispatch]);

  useEffect(() => {
    if (conversationId) {
      setSelectedMenuKeys([conversationId]);
    }
  }, [conversationId]);

  // Dynamically generate menu items based on the conversationList
  const menuItems: MenuItem[] =
    conversationList && groupList
      ? [
          getItem(
            'User',
            'sub1',
            <UserOutlined />,
            conversationList.map((item: ConversationItemSider) =>
              getItem(
                item.email,
                item._id,
                <>
                  <Badge count={item.unreadMessageCount} size="small">
                    <Avatar
                      src={`${process.env.REACT_APP_API_URL}/avatars/${item.avatar}`}
                      size="small"
                      style={{ backgroundColor: 'orange' }}
                    />
                  </Badge>
                </>,
                undefined,
                () => {
                  return onClickConversation(item._id);
                }
              )
            )
          ),
          getItem(
            'Group',
            'sub2',
            <UserOutlined />,
            groupList.map((item: GroupItemSider) =>
              getItem(
                item.name,
                item._id,
                <>
                  <Badge count={item.unreadMessageCount} size="small">
                    <Avatar
                      src={`${process.env.REACT_APP_API_URL}/avatars/${item.avatar}`}
                      size="small"
                      style={{ backgroundColor: 'orange' }}
                    />
                  </Badge>
                </>,
                undefined,
                () => onClickGroupConversation(item._id, item.groupId)
              )
            )
          ),
        ]
      : [getItem('User', 'sub1', <UserOutlined />)];

  return (
    <SiderAntd
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="demo-logo-vertical" />
      <Menu
        theme="dark"
        mode="inline"
        items={menuItems}
        selectedKeys={selectedMenuKeys}
      />
    </SiderAntd>
  );
};

export default Sider;
