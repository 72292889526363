import { UserForm } from '../context/AuthContext';
import axiosInstance from '../utils/axiosInstance';

async function signin(user: UserForm): Promise<any> {
  return axiosInstance.post('/user/login', user);
}

async function register(user: {
  email: string;
  password: string;
}): Promise<any> {
  return axiosInstance.post('/user/register', user);
}

async function fetchUserProfile(): Promise<any> {
  try {
    return await axiosInstance.get('user/profile');
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

async function uploadAvatar(formData: FormData): Promise<any> {
  try {
    return await axiosInstance.patch('user/profile/avatar', formData);
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

async function changePassword(password: string): Promise<any> {
  try {
    return await axiosInstance.patch('user/password', { password });
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

async function deleteAccount(): Promise<any> {
  try {
    return await axiosInstance.delete('user');
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

async function searchByEmailFilteredByConversation(
  value: string
): Promise<any> {
  try {
    const users = await axiosInstance.get(`user/search/${value}`);
    return users;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

async function searchByEmailFilteredByGroup(
  groupId: string,
  value: string
): Promise<any> {
  try {
    const users = await axiosInstance.get(
      `user/search/${value}/group/${groupId}`
    );
    return users;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export {
  signin,
  register,
  fetchUserProfile,
  uploadAvatar,
  changePassword,
  deleteAccount,
  searchByEmailFilteredByConversation,
  searchByEmailFilteredByGroup,
};
