import { useEffect, useState } from 'react';
import { Badge, Button, Card, Input, Modal, Space, message } from 'antd';
import Meta from 'antd/es/card/Meta';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { UserProfile, useAuth } from '../../../context/AuthContext';
import UploadAvatar from './UploadAvatar';
import api from '../../../api';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { fetchMessagesThunk, selectConversationId } from '../dashboardSlice';
import { UserStatus } from '../header/Header';
import updateAuthToken from '../../../utils/updateAuthToken';

const Profile = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const conversationId = useAppSelector(selectConversationId);
  const dispatch = useAppDispatch();

  const { userProfile: profile, setUserProfile } = useAuth();

  const handleChangePassword = async () => {
    try {
      await api.user.changePassword(newPassword);
      message.success('Password changed successfully');
    } catch (error) {
      message.error('Password change failed');
    }
  };

  const handleDeleteAccount = async () => {
    try {
      await api.user.deleteAccount();
      updateAuthToken(null);
      message.success('Account deleted successfully');
    } catch (error) {
      message.error('Failed to delete account');
    } finally {
      setDeleteModalVisible(false);
    }
  };

  const handleCustomRequest = async ({ file }: UploadRequestOption) => {
    try {
      const formData = new FormData();
      formData.append('avatar', file);

      const response = await api.user.uploadAvatar(formData);

      if (response.status === 200) {
        const newProfile = {
          ...profile,
          avatar: response.data.avatar,
        } as UserProfile;
        setUserProfile(newProfile);
        message.success('File uploaded successfully');
        if (conversationId) {
          dispatch(fetchMessagesThunk(conversationId));
        }
      }
    } catch (error) {
      message.error('File upload failed');
    }
  };

  const showDeleteModal = () => {
    setDeleteModalVisible(true);
  };

  const hideDeleteModal = () => {
    setDeleteModalVisible(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Card>
        <Space direction="vertical">
          <Meta
            avatar={
              <UploadAvatar
                avatarUrl={`${process.env.REACT_APP_API_URL}/avatars/${profile?.avatar}`}
                onCustomRequest={handleCustomRequest}
              />
            }
            title=""
            description=""
          />
          <p>Email : {profile?.email}</p>
          {profile?.status && (
            <Badge
              status={`${UserStatus[profile.status].badge}`}
              text={`${UserStatus[profile.status].name}`}
            />
          )}

          <p>Created at : {profile?.createdAt}</p>
          <Space direction="horizontal">
            <Input.Password
              placeholder="Change password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <Button
              type="primary"
              disabled={newPassword.length < 3}
              onClick={handleChangePassword}
            >
              Renew
            </Button>
          </Space>
          <Button danger onClick={showDeleteModal}>
            Delete Account
          </Button>
        </Space>
      </Card>

      <Modal
        title="Delete Account"
        open={deleteModalVisible}
        onOk={handleDeleteAccount}
        onCancel={hideDeleteModal}
        okText="Delete"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete your account?</p>
      </Modal>
    </div>
  );
};

export default Profile;
