import Login from './components/auth/login/Login';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import Register from './components/auth/register/Register';
import DashboardLayout from './components/dashboard/Dashboard';
import Profile from './components/dashboard/profile/Profile';
import styles from './app.module.css';
import { Provider } from 'react-redux';
import { store } from './store';

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <AuthProvider>
          <div className={styles.app}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route element={<Register />} path="/register" />
              <Route element={<PrivateRoutes />}>
                <Route element={<DashboardLayout />} path="/" />
                <Route element={<Profile />} path="/profile" />
              </Route>
            </Routes>
          </div>
        </AuthProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
