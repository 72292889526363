import { useState } from 'react';
import { Layout, Select, Avatar, SelectProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import NewGroup from '../group/NewGroup';
import api from '../../../api';
import { setUserModalWithSocketThunk } from '../dashboardSlice';
import { useAppDispatch } from '../../../hooks';
import UserProfileModal from '../profile/UserProfileModal';

const { Header: AntdHeader } = Layout;
let timeout: ReturnType<typeof setTimeout> | null;

type SelectedUser = {
  value: string;
  label: string;
  avatar: string;
  updatedAt: string;
  createdAt: string;
  conversationId: string;
  status: UserStatusKeys;
};

export const UserStatus = {
  online: { name: 'online', badge: 'success' },
  offline: { name: 'offline', badge: 'default' },
  idle: { name: 'idle', badge: 'error' },
} as const;

export type UserStatusKeys = keyof typeof UserStatus;

type HeaderProps = {
  onClickConversation: (conversationId: string) => Promise<any>;
};

const Header = ({ onClickConversation }: HeaderProps) => {
  const navigate = useNavigate();
  const { userProfile: profile, socket } = useAuth();
  const [options, setOptions] = useState<SelectProps['options']>([]);
  const dispatch = useAppDispatch();

  const handleProfileClick = () => {
    navigate('/profile');
  };

  const handleSelectionChange = (status: UserStatusKeys) => {
    socket?.emit('changeStatus', { status });
  };

  const handleSelectChange = async (value: any, opt: SelectedUser) => {
    const userModal = {
      ...opt,
      _id: opt.value,
      email: opt.label,
    };
    await dispatch(setUserModalWithSocketThunk({ socket, userModal }));
  };

  const handleConversationClick = (convId: string) => {
    onClickConversation(convId);
    setOptions([]);
    dispatch(setUserModalWithSocketThunk({ socket, userModal: null }));
  };

  const handleSearch = async (value: string) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    if (value) {
      timeout = setTimeout(async () => {
        const { data } = await api.user.searchByEmailFilteredByConversation(
          value
        );
        setOptions([data.user]);
      }, 500);
    } else {
      setOptions([]);
    }
  };

  return (
    <AntdHeader
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Select
        allowClear
        showSearch
        placeholder="Please select"
        style={{ width: '100%' }}
        defaultActiveFirstOption={false}
        suffixIcon={null}
        filterOption={false}
        onSearch={handleSearch}
        onSelect={handleSelectChange}
        notFoundContent={null}
        options={(options || []).map((d) => ({
          value: d?._id,
          label: d?.email,
          avatar: d?.avatar,
          updatedAt: d?.updatedAt,
          createdAt: d?.createdAt,
          conversationId: d?.conversationId,
          status: d?.status,
        }))}
      />
      <UserProfileModal onChatBtnClick={handleConversationClick} />
      <NewGroup />
      <Select
        placeholder="User status"
        value={profile?.status}
        style={{ width: 120 }}
        onChange={handleSelectionChange}
        options={[
          { value: UserStatus.online.name, label: UserStatus.online.name },
          { value: UserStatus.offline.name, label: UserStatus.offline.name },
          { value: UserStatus.idle.name, label: UserStatus.idle.name },
        ]}
      />

      <Avatar
        onClick={handleProfileClick}
        src={`${process.env.REACT_APP_API_URL}/avatars/${profile?.avatar}`}
        size={50}
        style={{ cursor: 'pointer', backgroundColor: 'orange' }}
      />
    </AntdHeader>
  );
};

export default Header;
