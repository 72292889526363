import {
  ConversationItemSider,
  GroupItemSider,
} from '../components/dashboard/sider/siderSlice';
import axiosInstance from '../utils/axiosInstance';

async function fetchConversations(): Promise<ConversationItemSider[]> {
  try {
    const res = await axiosInstance.get('/conversation/private');
    return res.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

async function fetchGroupConversations(): Promise<GroupItemSider[]> {
  try {
    const res = await axiosInstance.get('/conversation/group');
    return res.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

async function fetchMessages(conversationId: string): Promise<any> {
  try {
    return axiosInstance.get(`/conversation/${conversationId}`);
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

async function addConversation(userId: string): Promise<any> {
  try {
    const conversation = axiosInstance.post(`/conversation`, { userId });
    return conversation;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export {
  fetchConversations,
  fetchMessages,
  addConversation,
  fetchGroupConversations,
};
