import axiosInstance from './axiosInstance';

// Function to update the authentication token and notify other parts of the application
const updateAuthToken = (token: string | null): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      if (token) {
        axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
        localStorage.setItem('authToken', token);
      } else {
        delete axiosInstance.defaults.headers.Authorization;
        localStorage.removeItem('authToken');
      }

      const authTokenEvent = new CustomEvent('authTokenChanged', {
        detail: {
          authToken: token,
          callback: () => {
            resolve();
          },
        },
      });

      window.dispatchEvent(authTokenEvent);
    } catch (error) {
      console.error('Error updating auth token:', error);
      reject(error);
    }
  });
};
export default updateAuthToken;
