import { useEffect, useState } from 'react';
import { Avatar, message, Upload } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import type { UploadRequestOption } from 'rc-upload/lib/interface';

type UploadAvatarProps = {
  avatarUrl: string;
  onCustomRequest: (options: UploadRequestOption) => Promise<void> | void;
};

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

const UploadAvatar = ({ avatarUrl, onCustomRequest }: UploadAvatarProps) => {
  const [avatar, setAvatar] = useState<string>();

  useEffect(() => {
    setAvatar(avatarUrl);
  }, [avatarUrl]);

  return (
    <>
      <Upload
        name="avatar"
        listType="picture-circle"
        showUploadList={false}
        beforeUpload={beforeUpload}
        customRequest={onCustomRequest}
      >
        <Avatar
          src={avatar}
          size={64}
          style={{ cursor: 'pointer', backgroundColor: 'orange' }}
        />
      </Upload>
    </>
  );
};

export default UploadAvatar;
