import axios from 'axios';
import updateAuthToken from './updateAuthToken';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

// group/some-string/avatar
const regex = /^\group\/.+\/avatar$/;

const fileRequests = ['user/profile/avatar', '/group'];
const fileMethods = ['patch', 'post'];
axiosInstance.interceptors.request.use((config) => {
  // Modify the headers for specific requests
  if (
    fileMethods.includes(config.method) &&
    (fileRequests.includes(config.url) || regex.test(config.url))
  ) {
    config.headers['Content-Type'] = 'multipart/form-data';
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (!err.response || err.response.status === 401) {
      await updateAuthToken(null);
    }
    throw err;
  }
);

export default axiosInstance;
