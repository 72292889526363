import { GroupDetails } from '../components/dashboard/dashboardSlice';
import { GroupItemSider } from '../components/dashboard/sider/siderSlice';
import axiosInstance from '../utils/axiosInstance';

async function createGroup(formData: FormData): Promise<GroupItemSider> {
  try {
    const res = await axiosInstance.post('/group', formData);
    return res.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

async function fetchGroupDetails(groupId: string): Promise<any> {
  try {
    const res = await axiosInstance.get(`/group/${groupId}/details`);
    return res;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

async function uploadAvatar(groupId: string, formData: FormData): Promise<any> {
  try {
    return await axiosInstance.patch(`group/${groupId}/avatar`, formData);
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

async function updateGroup(
  groupId: string,
  data: Omit<Partial<GroupDetails>, '_id'>
): Promise<any> {
  try {
    return await axiosInstance.patch(`group/${groupId}`, data);
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

async function fetchGroupParticipants(groupId: string): Promise<any> {
  try {
    const res = await axiosInstance.get(`/group/${groupId}/participants`);
    return res;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export enum UpdateGroupParticipantMethod {
  remove = 'remove',
  add = 'add',
  edit = 'edit',
}

async function updateGroupParticipant(
  groupId: string,
  method: UpdateGroupParticipantMethod,
  data: {
    email: string;
  }
): Promise<any> {
  try {
    return await axiosInstance.patch(
      `group/${groupId}/${method}/participant`,
      data
    );
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export {
  createGroup,
  fetchGroupDetails,
  uploadAvatar,
  updateGroup,
  fetchGroupParticipants,
  updateGroupParticipant,
};
