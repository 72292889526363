import Header, { UserStatusKeys } from './header/Header';
import { Layout, Tabs, TabsProps } from 'antd';
import Sider from './sider/Sider';
import { useEffect, useState } from 'react';
import { Content } from 'antd/es/layout/layout';
import { useAuth } from '../../context/AuthContext';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  fetchGroupDetailsThunk,
  fetchMessagesThunk,
  resetGroupDetails,
  selectConversationId,
  selectGroupDetails,
  selectMessageList,
  selectUserModal,
  setUserModalWithSocketThunk,
  updateUserDataModal,
  updateUserFromMessages,
} from './dashboardSlice';
import { Chat, Message } from './chat/Chat';
import { GroupDetails } from './group/GroupDetails';
import { GroupDetailsAdmin } from './group/GroupDetailsAdmin';
import { GroupParticipants } from './group/GroupParticipants';
import api from '../../api';
import { UserDataModal } from './profile/UserProfileModal';

const DashboardLayout: React.FC = () => {
  const [tabsItems, setTabsItems] = useState<TabsProps['items']>([]);
  const { socket, userProfile } = useAuth();
  const groupDetails = useAppSelector(selectGroupDetails);
  const dispatch = useAppDispatch();
  const [tabKey, setTabKey] = useState<string | undefined | null>(undefined);
  const conversationId = useAppSelector(selectConversationId);
  const dataModal = useAppSelector<null | undefined | UserDataModal>(
    selectUserModal
  );
  const messages = useAppSelector<Message[]>(selectMessageList);

  useEffect(() => {
    const changeStatus = async ({
      userId,
      status,
    }: {
      userId: string;
      status: UserStatusKeys;
    }) => {
      if (dataModal && dataModal._id === userId) {
        dispatch(updateUserDataModal({ status }));
      }

      if (messages) {
        dispatch(updateUserFromMessages({ _id: userId, status }));
      }
    };

    socket?.on('changeStatus', changeStatus);

    return () => {
      socket?.off('changeStatus', changeStatus);
    };
  }, [dataModal]);

  const handleConversationClick = async (convId: string) => {
    try {
      if (convId === conversationId) {
        return;
      }
      setTabKey((state) => (state ? null : state));
      await dispatch(fetchMessagesThunk(convId));
      socket!.emit('joinConversation', convId);
      if (groupDetails) dispatch(resetGroupDetails());
      setTabsItems(convTabsItems);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGroupConversationClick = async (
    convId: string,
    pGroupId: string
  ) => {
    try {
      await dispatch(fetchMessagesThunk(convId));
      socket!.emit('joinConversation', convId);
      if (groupDetails === null || typeof groupDetails === 'undefined') {
        setTabsItems(groupTabsItems);
      }
      dispatch(fetchGroupDetailsThunk(pGroupId));
    } catch (error) {
      console.error(error);
    }
  };

  const handleUserModalOpen = async (email: string) => {
    if (email === userProfile?.email) return;
    const { data } = await api.user.searchByEmailFilteredByConversation(email);
    await dispatch(
      setUserModalWithSocketThunk({ socket, userModal: data.user })
    );
  };

  const convTabsItems: TabsProps['items'] = [
    {
      key: 'chat',
      label: 'Chat',
      children: <Chat onUserAvatarClick={handleUserModalOpen} />,
    },
  ];

  const groupTabsItems: TabsProps['items'] = [
    {
      key: 'chat',
      label: 'Chat',
      children: <Chat onUserAvatarClick={handleUserModalOpen} />,
    },
    {
      key: 'groupDetails',
      label: 'Group details',
      children: 'Group details',
    },
    {
      key: 'groupParticipants',
      label: 'Participants',
      children: 'Content of Tab Pane 3',
    },
  ];

  return (
    <Layout>
      <Header onClickConversation={handleConversationClick} />
      <Layout style={{ minHeight: '100vh' }} hasSider>
        <Sider
          onClickConversation={handleConversationClick}
          onClickGroupConversation={handleGroupConversationClick}
        />
        <Content>
          {
            <Tabs
              defaultActiveKey="chat"
              items={tabsItems}
              onChange={setTabKey}
            />
          }
          {tabKey === 'groupDetails' &&
            (groupDetails?.userIsAdmin ? (
              <GroupDetailsAdmin />
            ) : (
              <GroupDetails />
            ))}
          {tabKey === 'groupParticipants' && (
            <GroupParticipants onUserAvatarClick={handleUserModalOpen} />
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
