import { createContext, useContext, useEffect, useRef, useState } from 'react';
import api from '../api';
import updateAuthToken from '../utils/updateAuthToken';
import { message } from 'antd';
import { Socket, io } from 'socket.io-client';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  ConversationItemSider,
  GroupItemSider,
  addConversation,
  addGroupConversation,
  fetchGroupsThunk,
  updateConversationItem,
  updateConversationUnreadMessageCount,
  updateGroupItem,
  updateGroupUnreadMessageCount,
} from '../components/dashboard/sider/siderSlice';
import { clearRootState } from '../store';
import {
  asyncSocketConnect,
  asyncSocketDisconnect,
  asyncSocketEmit,
} from '../utils/asyncSocketEmit';
import {
  GroupDetails,
  addMessage,
  clearDashboardState,
  fetchGroupParticipantsThunk,
  selectGroupDetails,
  selectUserModal,
  updateGroupDetails,
  updateUserFromMessages,
} from '../components/dashboard/dashboardSlice';
import { UpdateGroupParticipantMethod } from '../api/group';
import { useNavigate } from 'react-router-dom';
import { Message, MessageSender } from '../components/dashboard/chat/Chat';
import { UserStatusKeys } from '../components/dashboard/header/Header';
import { UserDataModal } from '../components/dashboard/profile/UserProfileModal';
import { mergeObjectsFields } from '../utils/mergeObjectsFields';

export type UserProfile = {
  email: string;
  avatar: string;
  createdAt: string;
  updatedAt: string;
  status: UserStatusKeys;
};

export type UserForm = {
  email: string;
  password: string;
  confirmPassword?: string;
};

export type AuthContextType = {
  isAuth: boolean;
  userProfile: UserProfile | null;
  token: string | null;
  socket: Socket | null;
  signin: (user: UserForm) => void;
  setUserProfile: (profile: UserProfile) => void;
};

const AuthContext = createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [token, setToken] = useState<string | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [socket, setSocket] = useState<Socket | null>(null);
  const groupDetails = useAppSelector(selectGroupDetails);
  const dataModal = useAppSelector<null | undefined | UserDataModal>(
    selectUserModal
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const initialized = useRef<boolean>(false);

  const handleTokenChange = async (evt: CustomEvent) => {
    // Token has changed, update the state
    setToken(evt.detail.authToken);
    if (evt.detail.authToken === null) {
      await asyncSocketEmit(socket!, 'disconnectUserSockets');
      await signout();
    }
    evt.detail.callback();
  };

  useEffect(() => {
    // Add event listener for storage changes
    window.addEventListener(
      'authTokenChanged',
      handleTokenChange as unknown as EventListener
    );

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener(
        'authTokenChanged',
        handleTokenChange as unknown as EventListener
      );
    };
  }, [socket]);

  useEffect(() => {
    // To avoid calling twice useEffect causing by StrictMode
    if (!initialized.current) {
      (initialized as React.MutableRefObject<boolean>).current = true;

      const initialize = async () => {
        const storedToken = localStorage.getItem('authToken');

        if (storedToken) {
          updateAuthToken(storedToken);

          const socket = initSocket(storedToken);
          setSocket(socket);
          await asyncSocketConnect(socket);

          getUserProfile();
        }
      };

      initialize();
    }
  }, []);

  useEffect(() => {
    if (!socket) return;

    const handleGroupParticipantsEdited = async (payload: {
      groupId: string;
      userIsModified: boolean;
      method: UpdateGroupParticipantMethod;
      logMessage: Message;
    }) => {
      const { groupId, userIsModified, method, logMessage } = payload;
      const groupConversationIsSelected = groupDetails?._id === groupId;

      if (userIsModified && method === UpdateGroupParticipantMethod.remove) {
        if (groupConversationIsSelected) {
          dispatch(clearDashboardState());
          navigate('/login');
        }
        dispatch(fetchGroupsThunk());
      } else {
        if (groupConversationIsSelected) {
          dispatch(addMessage(logMessage));
          dispatch(fetchGroupParticipantsThunk(groupId));
        }
        if (userIsModified) {
          if (method === UpdateGroupParticipantMethod.add)
            dispatch(fetchGroupsThunk());
          else if (method === UpdateGroupParticipantMethod.edit)
            dispatch(
              updateGroupDetails({ userIsAdmin: !groupDetails?.userIsAdmin })
            );
        }
      }
    };

    socket.on('groupParticipantsEdited', handleGroupParticipantsEdited);

    return () => {
      socket.off('groupParticipantsEdited', handleGroupParticipantsEdited);
    };
  }, [groupDetails]);

  const initSocket = (token: string) => {
    const newSocket = io(`${process.env.REACT_APP_API_URL}`, {
      auth: { token },
      autoConnect: false,
    });

    newSocket.on('connect', () => {});

    newSocket.on('signout', async () => {
      await signout();
    });

    const handleNewConversation = (conversation: {}) => {
      dispatch(addConversation(conversation));
      message.success('New conversation added!');
    };

    const handleNewGroupConversation = (conversation: GroupItemSider) => {
      dispatch(addGroupConversation(conversation));
      message.success('New group added!');
    };

    const updateUserProfile = (profile: Partial<UserProfile>) => {
      setUserProfile((state) => mergeObjectsFields(state, profile));
    };

    const updateUnreadMessageCount = (value: {
      conversation: string;
      count: number;
      isGroup: boolean;
    }) => {
      if (value.isGroup === true) {
        dispatch(updateGroupUnreadMessageCount(value));
      } else {
        dispatch(updateConversationUnreadMessageCount(value));
      }
    };

    const handleError = (err: string) => {
      console.error(err);
    };

    const handleGroupEdited = (
      data: Partial<GroupDetails> & { _id: string }
    ) => {
      if (groupDetails?._id === data._id) {
        dispatch(updateGroupDetails(data));
      }
      const { _id, ...rest } = data;
      dispatch(updateGroupItem({ ...rest, groupId: _id }));
    };

    const handleUserUpdateMessages = (data: Partial<MessageSender>) => {
      dispatch(updateUserFromMessages(data));
    };

    const handleConversationItemUpdated = (
      data: Partial<ConversationItemSider> & {
        _id: string;
      }
    ) => {
      dispatch(updateConversationItem(data));
    };

    newSocket.on('newConversation', handleNewConversation);
    newSocket.on('newGroupConversation', handleNewGroupConversation);
    newSocket.on('updateUserProfile', updateUserProfile);
    newSocket.on('updateUnreadMessageCount', updateUnreadMessageCount);
    newSocket.on('groupEdited', handleGroupEdited);
    newSocket.on('updateUserMessages', handleUserUpdateMessages);
    newSocket.on('updateConversationItem', handleConversationItemUpdated);
    newSocket.on('error', handleError);

    return newSocket;
  };

  const getUserProfile = async () => {
    try {
      const { data } = await api.user.fetchUserProfile()!;
      data.avatar = data.avatar ?? 'default.png';
      setUserProfile(data);
      setIsAuth(true);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const signin = async (newUser: UserForm) => {
    try {
      const { data } = await api.user.signin(newUser);
      data.profile.avatar = data.profile.avatar ?? 'default.png';
      updateAuthToken(data.token);
      const socket = initSocket(data.token);
      setSocket(socket);
      await asyncSocketConnect(socket);
      setUserProfile(data.profile);
      setIsAuth(true);
    } catch (error) {
      message.error(
        'Unable to sign in. Please check your credentials and try again.'
      );
    }
  };

  const signout = async () => {
    setIsAuth(false);
    await asyncSocketDisconnect(socket!);
    setUserProfile(null);
    setSocket(null);
    setToken(null);
    dispatch(clearRootState());
  };

  const value = {
    isAuth,
    userProfile,
    token,
    socket,
    signin,
    getUserProfile,
    setUserProfile,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
