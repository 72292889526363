import { Socket } from 'socket.io-client';

export const asyncSocketEmit = (
  socket: Socket,
  eventName: string,
  data: any = null
) => {
  return new Promise((resolve, reject) => {
    socket?.once(eventName, (data) => {
      resolve(data);
    });

    socket?.once('error', (error) => {
      reject(error);
    });

    socket?.emit(eventName, data);
  });
};

export const asyncSocketDisconnect = (socket: Socket, data: any = null) => {
  return new Promise((resolve, reject) => {
    socket?.once('disconnect', (data) => {
      resolve(data);
    });

    socket?.once('error', (error) => {
      reject(error);
    });

    socket?.disconnect();
  });
};

export const asyncSocketConnect = (socket: Socket, data: any = null) => {
  return new Promise((resolve, reject) => {
    socket?.once('connected', (data) => {
      resolve(data);
    });

    socket?.once('error', (error) => {
      reject(error);
    });

    socket?.connect();
  });
};
