import { Button, Form, Input, Modal, Select, SelectProps, message } from 'antd';
import Meta from 'antd/es/card/Meta';
import { useEffect, useState } from 'react';
import UploadAvatar from '../profile/UploadAvatar';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import api from '../../../api';
import { RcFile } from 'antd/es/upload';
import { useAppDispatch } from '../../../hooks';
import { addGroupThunk } from '../sider/siderSlice';

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

async function createFileFromUrl(url: string, name: string, type: string) {
  let response = await fetch(url);
  let data = await response.blob();
  let metadata = {
    type,
  };
  return new File([data], name, metadata);
}

let timeout: ReturnType<typeof setTimeout> | null;

const NewGroup = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState<string>('');
  const [form] = Form.useForm();
  const [options, setOptions] = useState<SelectProps['options']>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setAvatarPreview(process.env.REACT_APP_API_URL + '/avatars/default.jpg');
    getAvatarFromUrl(
      process.env.REACT_APP_API_URL + '/avatars/default.jpg',
      'default.jpg',
      'image/jpeg'
    );
  }, []);

  const getAvatarFromUrl = async (url: string, name: string, type: string) => {
    const file = await createFileFromUrl(url, name, type);
    form.setFieldValue('avatar', file);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSearch = async (value: string) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    if (value) {
      timeout = setTimeout(async () => {
        const { data } = await api.user.searchByEmailFilteredByConversation(
          value
        );
        setOptions([data.user]);
      }, 500);
    } else {
      setOptions([]);
    }
  };

  const handleSelectChange = (value: string[]) => {
    form.setFieldValue('participants', value);
  };

  const handleCustomRequest = ({ file }: UploadRequestOption) => {
    try {
      form.setFieldValue('avatar', file);

      getBase64(file as RcFile, (url) => {
        setAvatarPreview(url);
      });
    } catch (error) {
      console.error('Error :', error);
    }
  };

  const onFinish = async ({
    avatar,
    name,
    description,
    participants = [],
  }: any) => {
    const formData = new FormData();
    try {
      if (name.length < 3)
        throw new Error('Group name need to be at least 3 characters');

      formData.append('avatar', avatar);
      formData.append('name', name);
      formData.append('description', description);
      formData.append('participants', JSON.stringify(participants));
      await dispatch(addGroupThunk(formData));
      message.success('Group created successfully!');
    } catch (e) {
      console.error(e);
      message.error('Error when creating group');
    }
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Create a group
      </Button>

      <Modal
        title="Basic Modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          layout={'vertical'}
          form={form}
          onFinish={onFinish}
          style={{ maxWidth: 600 }}
        >
          <Form.Item label="avatar" name="avatar">
            <Meta
              avatar={
                <UploadAvatar
                  avatarUrl={avatarPreview}
                  onCustomRequest={handleCustomRequest}
                />
              }
              title=""
              description=""
            />
          </Form.Item>
          <Form.Item label="name" name="name">
            <Input placeholder="input placeholder" />
          </Form.Item>

          <Form.Item label="description" name="description">
            <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item label="participants" name="participants">
            <Select
              mode="multiple"
              allowClear
              showSearch
              placeholder="Please select"
              style={{ width: '100%' }}
              defaultActiveFirstOption={false}
              suffixIcon={null}
              filterOption={false}
              onSearch={handleSearch}
              onChange={handleSelectChange}
              notFoundContent={null}
              options={(options || []).map((d) => ({
                value: d?._id,
                label: d?.email,
              }))}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default NewGroup;
