import React from 'react';
import { Form, Input, Button, notification } from 'antd';
import styles from './register.module.css';
import { Link } from 'react-router-dom';
import { UserForm } from '../../../context/AuthContext';
import api from '../../../api';
const { user } = api;

const Register: React.FC = () => {
  const onFinish = async (values: UserForm) => {
    try {
      await user.register(values);
      notification.success({
        message: 'Registration Successful',
        description: 'You have successfully registered. Please log in.',
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Registration Failed',
        description:
          'There was an error during registration. Please try again.',
      });
    }
  };

  return (
    <div className={styles.container}>
      <Form name="basic" onFinish={onFinish} className={styles.form}>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          rules={[
            { required: true, message: 'Please confirm your password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('The two passwords do not match!');
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Link to="/login">Login</Link>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Register
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Register;
