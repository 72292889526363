import {
  Avatar,
  Badge,
  Button,
  Divider,
  Input,
  List,
  Tooltip,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { UserProfile, useAuth } from '../../../context/AuthContext';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  addMessage,
  selectConversationId,
  selectMessageList,
} from '../dashboardSlice';
import { UserStatus, UserStatusKeys } from '../header/Header';

enum logEventType {
  createGroup = 'createGroup',
  addParticipant = 'addParticipant',
  leaveParticipant = 'leaveParticipant',
  removeParticipant = 'removeParticipant',
  editGroup = 'editGroup',
  addAdmin = 'addAdmin',
  removeAdmin = 'removeAdmin',
}

export type MessageSender = {
  status: UserStatusKeys;
  email: string;
  _id: string;
  avatar: string;
};

export type Message = {
  _id: string;
  sender: MessageSender;
  content: string;
  createdAt: string;
  logEventType?: logEventType;
};

export type ChatProps = {
  onUserAvatarClick: (conversationId: string) => void;
};

export const Chat = ({ onUserAvatarClick }: ChatProps) => {
  const { socket, userProfile } = useAuth();
  const conversationId = useAppSelector(selectConversationId);
  const dispatch = useAppDispatch();
  const [newMessage, setNewMessage] = useState('');
  const messages = useAppSelector<Message[]>(selectMessageList);

  useEffect(() => {
    if (socket) {
      const handleNewMessage = (msg: any) => {
        if (
          conversationId === msg.conversationId &&
          msg.content.trim() !== ''
        ) {
          dispatch(addMessage(msg));
          setNewMessage('');
        }
      };

      socket.on('newMessage', handleNewMessage);

      return () => {
        socket.off('newMessage', handleNewMessage);
      };
    }
  }, [conversationId]);

  const handleSendMessage = (userProfile: UserProfile | null) => {
    if (!userProfile) return;

    socket!.emit('newMessage', {
      content: newMessage,
      conversationId,
      sender: {
        email: userProfile.email,
        avatar: userProfile.avatar,
        status: userProfile.status,
      },
    });
  };

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={messages}
        renderItem={({ content, sender, createdAt, logEventType }) => {
          const timestamp = new Date(createdAt).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          });

          return (
            <>
              <List.Item>
                {logEventType ? (
                  <Typography.Text
                    italic={true}
                  >{`${content}`}</Typography.Text>
                ) : (
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={`${process.env.REACT_APP_API_URL}/avatars/${sender.avatar}`}
                        style={{ cursor: 'pointer', backgroundColor: 'orange' }}
                        onClick={() => onUserAvatarClick(sender.email!)}
                      />
                    }
                    title={sender.email}
                    description={<p>{content}</p>}
                  />
                )}
                {!logEventType && sender.status && (
                  <Badge
                    status={`${UserStatus[sender.status].badge}`}
                    text={`${UserStatus[sender.status].name}`}
                  />
                )}
                <Divider type="vertical" />
                <Tooltip title={timestamp}>
                  <span>Created at: {timestamp}</span>
                </Tooltip>
              </List.Item>
            </>
          );
        }}
      />

      <Input
        placeholder="Type your message..."
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
      />
      <Button onClick={() => handleSendMessage(userProfile)} type="primary">
        Send
      </Button>
    </>
  );
};
