import { GroupDetails } from './group/GroupDetails';
import { Message, MessageSender } from './chat/Chat';
import {
  SerializedError,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../store';
import api from '../../api';
import { mergeObjectsFields } from '../../utils/mergeObjectsFields';
import { UpdateGroupParticipantMethod } from '../../api/group';
import { UserDataModal } from './profile/UserProfileModal';
import { UserStatusKeys } from './header/Header';
import { Socket } from 'socket.io-client';
import { asyncSocketEmit } from '../../utils/asyncSocketEmit';

export const fetchMessagesThunk = createAsyncThunk(
  'messages/fetch',
  async (conversationId: string) => {
    const { data } = await api.conversation.fetchMessages(conversationId);
    return { data, conversationId };
  }
);

export const fetchGroupDetailsThunk = createAsyncThunk(
  'groups/fetch',
  async (groupId: string) => {
    const { data } = await api.group.fetchGroupDetails(groupId);
    return { data };
  }
);

export const updateGroupThunk = createAsyncThunk(
  'groups/edit',
  async (payload: {
    groupId: string;
    data: Omit<Partial<GroupDetails>, '_id'>;
  }) => {
    const { data } = await api.group.updateGroup(payload.groupId, payload.data);
    return data;
  }
);

export const fetchGroupParticipantsThunk = createAsyncThunk(
  'groups/participants/fetch',
  async (groupId: string) => {
    const { data } = await api.group.fetchGroupParticipants(groupId);
    return data;
  }
);

export const updateGroupParticipantsThunk = createAsyncThunk(
  'groups/edit',
  async (payload: {
    groupId: string;
    method: UpdateGroupParticipantMethod;
    data: { email: string };
  }) => {
    const { data } = await api.group.updateGroupParticipant(
      payload.groupId,
      payload.method,
      payload.data
    );
  }
);

export const setUserModalWithSocketThunk = createAsyncThunk(
  'socket/emitSocketEvent',
  async (
    {
      socket,
      userModal,
    }: { socket: Socket | null; userModal: UserDataModal | null },
    { getState }
  ) => {
    if (!socket) return;
    const { dashboard } = getState() as RootState;

    const userId = userModal ? userModal._id : dashboard.userModal!._id;

    await asyncSocketEmit(socket, 'profileModal', {
      userId,
    });
    return userModal;
  }
);

export type GroupDetails = {
  _id: string;
  name: string;
  description: string;
  avatar: string;
  updatedAt: string;
  createdAt: string;
  createdBy: string;
  userIsAdmin: boolean;
};

export type GroupParticipantDataType = {
  key: string;
  email: string;
  isAdmin: boolean;
  status: UserStatusKeys;
  avatar: string;
  isLastAdmin?: boolean;
};

type State = {
  messageList: Message[];
  conversationId: undefined | string | null;
  groupDetails: undefined | GroupDetails | null;
  groupParticipants: [] | GroupParticipantDataType[];
  userModal: undefined | null | UserDataModal;
  error: SerializedError | null;
};

const initialState: State = {
  messageList: [],
  conversationId: undefined,
  groupDetails: undefined,
  groupParticipants: [],
  userModal: undefined,
  error: null,
};

export const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    addMessage(state, { payload }: { payload: Message }) {
      state.messageList.push(payload);
    },
    clearDashboardState: (state) => {
      return initialState;
    },
    resetGroupDetails: (state) => {
      state.groupDetails = null;
    },
    updateGroupDetails: (
      state,
      action: { payload: Omit<Partial<GroupDetails>, '_id'> }
    ) => {
      state.groupDetails = mergeObjectsFields(
        state.groupDetails,
        action.payload
      );
    },
    updateUserDataModal: (
      state,
      action: {
        payload: Omit<
          Partial<UserDataModal>,
          '_id' | 'createdAt' | 'conversationId'
        >;
      }
    ) => {
      state.userModal = mergeObjectsFields(state.userModal, action.payload);
    },
    updateUserFromMessages: (
      state,
      action: {
        payload: Partial<MessageSender>;
      }
    ) => {
      state.messageList = state.messageList.map((message) => {
        const newMessage = { ...message };
        if (newMessage.sender._id === action.payload._id) {
          newMessage.sender = mergeObjectsFields(
            newMessage.sender,
            action.payload
          );
        }
        return newMessage;
      });
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchMessagesThunk.fulfilled, (state, { payload }) => {
      state.messageList = [...payload.data];
      state.conversationId = payload.conversationId;
    });
    builder.addCase(fetchGroupDetailsThunk.fulfilled, (state, { payload }) => {
      state.groupDetails = payload.data;
    });
    builder.addCase(
      fetchGroupParticipantsThunk.fulfilled,
      (state, { payload }: { payload: GroupParticipantDataType[] }) => {
        const hasLastAdmin = payload.filter((p) => p.isAdmin).length === 1;
        const nextState = payload;
        if (hasLastAdmin) {
          const adminIndex = payload.findIndex(
            (participant) => participant.isAdmin
          );
          nextState[adminIndex].isLastAdmin = true;
        }
        state.groupParticipants = nextState;
      }
    );
    builder.addCase(
      fetchMessagesThunk.rejected ||
        fetchGroupDetailsThunk.rejected ||
        updateGroupThunk.rejected ||
        fetchGroupParticipantsThunk,
      (state, action) => {
        if (action.payload) {
          state.error = action.error;
        }
      }
    );
    builder.addCase(
      setUserModalWithSocketThunk.fulfilled,
      (state, { payload }) => {
        state.userModal = payload;
      }
    );
  },
});

export const {
  addMessage,
  clearDashboardState,
  resetGroupDetails,
  updateGroupDetails,
  updateUserDataModal,
  updateUserFromMessages,
} = dashboardSlice.actions;

export const selectMessageList = (state: RootState) =>
  state.dashboard.messageList;

export const selectConversationId = (state: RootState) =>
  state.dashboard.conversationId;

export const selectGroupDetails = (state: RootState) =>
  state.dashboard.groupDetails;

export const selectGroupParticipants = (state: RootState) =>
  state.dashboard.groupParticipants;

export const selectUserModal = (state: RootState) => state.dashboard.userModal;
